<template>
  <div>

    <div class="columns is-justify-content-center">
      <div class="column is-8">

        <section class="card">
          <sg-tab v-model="selectedAction" :items="['Activity', 'Alert']" class="px-4 border-top-0"/>
          <div class="card-content">

            <sg-tab v-model="selectedCategory"
                    active-class="is-primary is-gradient"
                    default-class="is-primary is-gradient is-outlined"
                    type="button" :items="['All', 'Cycle', 'Pond', 'Use Item', 'Water Quality', 'Warehouse', 'Stock', 'Feed Tray', 'Sampling']" class="mb-4"/>

            <div class="is-flex" v-for="k in 6" :key="k">
              <img
                  class="navbar-profile-img mr-4"
                  src="/img/assets/profile.jpg"
                  alt="Profile"
              />
              <div class="is-flex-grow-1">
                <h4 class="is-size-6 has-text-weight-bold">Irfan Fakhri</h4>
                <p>{{ $t('farm.pond') }} A1 from <span class="has-text-weight-bold">Block Dago has Updated its Bacteria section of water quality</span>
                </p>
                <div class="is-flex is-justify-content-space-between mt-5">
                  <span class="has-text-grey">11 Aug</span>
                  <span class="has-text-success">Water Quality</span>
                </div>
                <div class="dropdown-divider"></div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </div>

  </div>
</template>

<script>
import SgTab from "@/components/Sg/SgTab";

export default {
  name: "Notification",
  components: {SgTab},
  data: () => {
    return {
      selectedAction: 'Activity',
      selectedCategory: 'All'
    }
  },
  methods: {
    addRole() {
      this.addModal = false
      this.addRoleModal = true
    }
  }
}
</script>
